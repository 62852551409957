<template>
  <div id="ParticipantList">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row pt-2">
        <div class="col-sm-12">
          <h4 class="bg-secondary text-white text-start rounded p-1 ps-2">Participant list: {{divisionName}} division</h4>
          <div class="table-responsive">
            <table class="table caption-top table-striped text-start">
              <caption><h5 class="bg-success text-white rounded p-1 ps-2">{{categoryName}}</h5></caption>
              <thead>
              <tr>
                <th class="text-center" style="width: 50px">Sl</th>
                <th>Name</th>
                <th>School</th>
                <th style="width: 100px">Class</th>
                <th style="width: 110px">Reg. No.</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(participant,key) in participants" :key="key">
                <td class="text-center">{{key+1}}</td>
                <td>{{participant.name}}</td>
                <td>{{participant.school}}</td>
                <td>{{participant.class_name}}</td>
                <td>{{participant.reg_no}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Preloader from "../components/Preloader";

  export default {
    components:{
      Preloader,
    },
    name: "ParticipantList",
    data(){
      return{
        division:this.$route.params.name,
        id: this.$route.params.id,
        categoryId: this.$route.params.categoryId,
        categories:[],
        loaded:false,
        participants:[],
        categoryName:'',
        divisionName:'',
      }
    },
    created() {
      this.$http.get("get-category-participant-list/"+this.id+"/"+this.categoryId).then(function (response) {
        this.participants = response.body.participants;
        this.divisionName = response.body.division;
        this.categoryName = response.body.category;
        this.loaded=true;
      })
    }
  }
</script>

<style scoped>

</style>
